<script lang="ts">
	type FontData = {
		name: string
		path: string
	}

	import Card from './Card.svelte'

	let customText = 'ܐܒܪܗܡ ܒܪܐ ܕܝܠܕܐ'
	let size = 12

	const fontNames: FontData[] =
		`Assyria_Alqosh_Outline.ttf,Assyria_Alqosh.ttf,Assyria_Asheeta.ttf,Assyria_Babylon.ttf,Assyria_Bakhdedeh.ttf,Assyria_Barwar.ttf,Assyria_Baz.ttf,Assyria_BetNahrain_Outline.ttf,Assyria_BetNahrain.ttf,Assyria_Dawoodiya.ttf,Assyria_Dez.ttf,Assyria_Diyar_Bakr.ttf,Assyria_Hakkari.ttf,Assyria_Jeelou.ttf,Assyria_Mardin.ttf,Assyria_Nahla.ttf,Assyria_Nineveh.ttf,Assyria_Nusaibin.ttf,Assyria_Old_Estrangilion.ttf,Assyria_Salamas_Estrangilion.ttf,Assyria_Sapna_Estrangilion_Out.ttf,Assyria_Sapna_Estrangilion.ttf,Assyria_Shammisdin.ttf,Assyria_Tal-Kepeh.ttf,Assyria_Tkhouma.ttf,Assyria_Tyareh.ttf,Assyria_Urmi.ttf,Assyrian_Autonomy.ttf,Assyrian_Awgar_Okama.ttf,Assyrian_Bardaisan.ttf,Assyrian_Benyamin_Arsanis.otf,Assyrian_Celeucia_Church.ttf,Assyrian_Church_Kookheh.ttf,Assyrian_Gilgamish.ttf,Assyrian_Hope.ttf,Assyrian_Khuyada.ttf,Assyrian_Levi.ttf,Assyrian_Mar_Beyamin.ttf,Assyrian_Mar-Yosip_Khnanisho.ttf,Assyrian_Nation.ttf,Assyrian_Phraidon_Atouraya.ttf,Assyrian_Pride.ttf,Assyrian_Sefo.ttf,Assyrian_Star.ttf,Assyrian_Syriac.ttf,Assyrian_Tatian.ttf,Assyrian_Yosip_Handwrite.ttf,Assyrian_malik_Yaqu.ttf`
			// const fontNames: FontData[] = `Assyria_Alqosh_Outline.ttf,Assyria_Alqosh.ttf`
			.split(',')
			.map((name) => {
				name = name.trim()
				return { name, path: `./fonts/${name}` }
			})
</script>

<main>
	<div class="cardCollection">
		<div class="label option"><h2>Options</h2></div>
		<div class="inputs options">
			<div class="inputs">
				<label for="custom sentence">Custom Text:</label>
				<input
					type="text"
					class="custom sentence"
					placeholder="Type a Custom message to display using the fonts."
					bind:value={customText}
				/>
			</div>
			<!-- svelte-ignore a11y-label-has-associated-control -->
			<div class="inputs">
				<label> FontSize: </label>
				<input type="number" bind:value={size} min="6" max="200" />
			</div>
			<input type="range" bind:value={size} min="6" max="200" />
		</div>

		{#each fontNames as { name, path }}
			<div class="card">
				{#if customText != ''}
					<Card loc={path} {name} {size}>
						<span slot="name">{name.split('.')[0]}</span>
						<span style="font-size: {size}px" slot="text">{customText}</span>
					</Card>
				{:else}
					<Card loc={path} {name} {size}>
						<span slot="name">{name.split('.')[0]}</span>
					</Card>
				{/if}
			</div>
		{/each}
	</div>
</main>

<style>
	main {
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		padding: 1em;
		align-items: center;
		justify-content: space-evenly;
		max-width: 300px;
		margin: 0 auto;
	}
	.label.option {
		width: 90vw;
		/* margin: 0 0 0 2em; */
		text-align-last: start;
	}
	.cardCollection {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 90vw;
	}
	.options {
		/* margin: 2rem 0; */
		width: 90vw;
		min-width: fit-content;
		height: fit-content;
		display: flex;
		align-items: flex-start;
		justify-content: space-evenly;
		text-align-last: start;
		border: #44444444 solid 1px;
		border-radius: 10px;
	}
	.options label {
		font-size: 16px;
		font-weight: bold;
	}
	.options input {
		height: 100%;
	}
	.custom {
		width: 50vw;
	}
	.card {
		padding: 0.2em 0.2em;
	}
	@media (min-width: 640px) {
		main {
			max-width: none;
			margin: 0;
			padding: 0;
		}
	}
</style>
