<script>
	import { onMount } from 'svelte'
	import { downloadZip } from './lib/clientzip'

	export let loc = ''
	export let name = ''
	export let size = 24

	const fontfamily = name ? name.split('_').join('').split('.')[0] : ''

	const downloadFont = async () => {
		if (!loc) throw Error('No path to download this font.')

		const link = document.createElement('a')
		link.href = URL.createObjectURL(await downloadZip([fetch(loc)]).blob())
		link.download = `${name}.zip`
		link.click()
		link.remove()
	}

	function loadFont() {
		const myfont = new FontFace(fontfamily, `url(${loc.slice(2)})`)
		myfont.load().then((loadedFont) => {
			document.fonts.add(loadedFont)
			document.querySelector(`.${fontfamily}`).style.fontFamily = fontfamily
		})
		document.querySelector(`.${fontfamily}`).style.fontSize = `${size}px`
	}

	onMount(() => {
		loadFont()
	})
</script>

<article class="font-card">
	<h3>
		<slot name="name">
			<span class="missing">Unknown name</span>
		</slot>
	</h3>

	<div class={fontfamily + ' displayText'}>
		<slot name="text">
			<span style="font-size: {size}px" class="missing">Default display text!</span>
		</slot>
	</div>

	<div class="fontLocation">
		<button data-tooltip={`Download this font!`} class="download" on:click={downloadFont}
			>Download</button
		>
	</div>
</article>

<style>
	.font-card {
		border: 1px solid #aaa;
		border-radius: 12px;
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
		min-width: 290px;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-moz-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		flex-direction: column;
		max-width: 100%;
		min-height: 100%;
		position: relative;
		text-align-last: start;
	}

	h3 {
		padding: 0.2em 0.25em;
		margin: 0;
		border-bottom: 1px dashed #000000;
	}

	.displayText {
		padding: 1em 0.25em;
		font-size: 24px;
		line-height: 1.5;
	}

	.fontLocation {
		padding: 2em;
		/* height: calc(100% + ); */
		/* min-height: 10px; */
	}
	.fontLocation > button {
		position: absolute;
		bottom: 10px;
		right: 10px;
		background-color: transparent;
		cursor: pointer;
		border: #49494949 1px solid;
		border-radius: 4px;
		color: #252525;
	}

	.fontLocation > button:hover {
		background-color: dimgrey;
		color: white;
		box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
	}
	.fontLocation > button:active {
		transform: translateY(4px);
	}
	.missing {
		color: #0f0f0f;
	}
</style>
